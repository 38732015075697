import Moment from 'react-moment';
import React, { FC, useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Card from '@leuven2030/framework/Card/Card';
import CardImage from '@leuven2030/food/Card/CardImage';
import get from 'lodash/get';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import PrismicMeta from '@leuven2030/framework/Prismic/PrismicMeta';
import ImageBackground from '@leuven2030/framework/Image/ImageBackground';
import DateDot from '@leuven2030/food/Date/DateDot';

export type NewsCardOptions = {
  size?: 'small' | 'default';
};
const NewsCard: FC<
  NewsCardOptions & {
    title: string;
    description: string;
    group: any;
    published_date: string;
    cover: any;
    event_place: string;
    event_link: string;
    event_address: string;
    event_date_start: string;
    _meta: PrismicMeta;
  }
> = ({ title, size, group, cover, event_date_start, _meta }) => {
  const {
    prismic: {
      news_page: { read_more_label }
    }
  } = useContext(PageContext);

  const isSmall = size == 'small';
  const imageHeight = 160;

  return (
    <Card
      prismicMeta={_meta}
      tw="justify-between shadow-none bg-transparent overflow-visible"
    >
      <div>
        <div tw="">
          <div
            tw="space-y-2 "
            css={isSmall && tw`flex-col items-start space-x-0 space-y-1 pb-1`}
          >
            <div
              tw="my-2 text-sm leading-7 font-normal text-primary-400 truncate max-w-full"
              className="font-sohnebreit"
            >
              {group.title}
            </div>
          </div>
        </div>
        {cover && (
          <div
            tw="w-full relative "
            css={{ height: imageHeight, maxHeight: imageHeight }}
          >
            {/* <ImageBackground
              tw="absolute w-full h-full transform transition-transform duration-300 ease-in-out bg-center bg-cover rounded hover:scale-105"
              src={cover.url}
              imgixParams={{
                w: 400,
                h: 200,
                fit: 'crop'
              }}
            /> */}

            <CardImage src={cover.url}></CardImage>

            <div tw="flex flex-col justify-center absolute w-full h-full top-0 bottom-0">
              {get(group, '_meta.uid') == 'event' && (
                <div tw="flex items-center justify-center absolute -left-2 sm:-left-4 top-4">
                  <DateDot date={event_date_start} />
                </div>
              )}
            </div>
          </div>
        )}

        <div tw="p-0 mt-4">
          <p tw="text-lg leading-6 text-black font-medium hover:text-primary-400">
            {title}
          </p>
        </div>
      </div>
      {/* <div
        tw="py-3  space-y-2 "
        css={isSmall && tw`flex-col items-start space-x-0 space-y-1 `}
      >
        <Moment
          tw="text-gray-400 tracking-wide uppercase text-xs"
          parse="YYYY-MM-DD"
          format="dddd, D MMMM YYYY"
        >
          {published_date}
        </Moment>
        <div tw="flex flex-row items-center justify-between  space-x-2">
          <span tw="block text-base leading-6 text-sm text-primary-600 transition ease-in-out duration-150">
            {read_more_label}
          </span>
        </div>
      </div> */}
    </Card>
  );
};

export default NewsCard;
