import NavigationLink, {
  NavigationLinkProps
} from '@leuven2030/framework/Navigation/NavigationLink';
import React, { createContext, FC, useState } from 'react';
import 'twin.macro';
export const CardContext = createContext({
  isHovering: false
});

type Props = {} & NavigationLinkProps;

const Card: FC<Props> = ({ prismicMeta, children, ...props }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <NavigationLink
      prismicMeta={prismicMeta}
      tw="bg-white rounded-sm overflow-hidden  cursor-pointer relative shadow flex flex-col"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      <CardContext.Provider value={{ isHovering }}>
        {children}
      </CardContext.Provider>
    </NavigationLink>
  );
};

export default Card;
