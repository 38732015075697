import React from 'react';
import 'twin.macro';
import Moment from 'react-moment';

const DateDot = ({ date }) => {
  return (
    <div
      tw="w-14 h-14 flex flex-col items-center justify-center whitespace-nowrap rounded-full bg-primary-400 font-medium"
      className="font-sohne"
    >
      <Moment
        tw="text-white tracking-wide uppercase text-base leading-4"
        format="DD"
        className="font-sohne"
      >
        {date}
      </Moment>
      <Moment
        tw="capitalize text-sm text-white leading-4"
        format="MMM"
        className="font-sohne"
      >
        {date}
      </Moment>
    </div>
  );
};

export default DateDot;
